import * as Sentry from "@sentry/nuxt"
import LogRocket from "logrocket"

const runtimeConfig = useRuntimeConfig()

Sentry.init( {
  dsn: runtimeConfig.public.sentry.dsn,
  enabled: false /* process.env.NODE_ENV === "production" */,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration( {
      // Additional SDK configuration goes in here, for example:
      colorScheme: "dark",
      autoInject: runtimeConfig.public.sentry.enableFeedback,
      triggerLabel: "Website Problem?",
      formTitle: "Report an issue",
      submitButtonLabel: "Submit Issue",
      showEmail: true,
      onFormOpen: () => {
        Sentry.setContext( "screenSize", {
          width: window.innerWidth,
          height: window.innerHeight,
        } )
        Sentry.setTag( "screen.width", window.innerWidth )
        Sentry.setTag( "screen.height", window.innerHeight )
        LogRocket.getSessionURL( sessionURL => {
          Sentry.setTag( "logRocket.sessionURL", sessionURL )
        } )
      }
    } ),
  ],
  // Tracing
  // We recommend adjusting this value in production, or using a tracesSampler for finer control.
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [ "localhost", /^https:\/\/yourserver\.io\/api/ ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
} )
